export const grabLinkFromText = text => {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
    return text.match(regex)
}

export const findInsertPoint = text  => {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
    const match = text.match(regex);
    
    if (match) return text.indexOf(match[0]);

    return -1
}

// export const processTextWithLink = text => {
//     const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
//     const link = grabLinkFromText(text)
//     if (link) {
//         const insertionPoint =findInsertPoint(text, regex)
//         const newText = text.slice(0, insertionPoint) + `<a href=${link} />` + text.slice(insertionPoint, insertionPoint+ link[0].length) + `</a>` + text.slice(insertionPoint+ link[0].length);
//         return newText
//     }
//     return text
// }