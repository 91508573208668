import React, { useState, useEffect } from "react"
import Layout from '../components/layout'
import "normalize.css"
import {
  listItemTitle,
  upArrow,
  tweet, timestamp, word, aday, visible, notVisible
} from '../components/layout.module.css'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { grabLinkFromText, findInsertPoint} from '../helper.js';
import { MdOutlineEdit, MdDone } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { IoReturnDownBack } from "react-icons/io5";
import { Audio } from 'react-loader-spinner'



const Wall = () => {

  const [comments, setComments] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const [updating, setUpdating] = useState(false)
  const [name, setName] = useState(null)
  const [content, setContent] = useState(null)


  const handleSubmit = async (e) => {

    try {
      e.preventDefault();
      const form = e.target;
      const formData = new FormData(form);

      setSubmitting(true);

      const response = await fetch('https://api-routes-rest-mocha.vercel.app/api/addComment', { 
        method: form.method, 
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString()
      });

      if (response.ok) {
        console.log('Request succeeded!');
        fetchComments()
        form.reset()
        setSubmitting(false)
      } else {
        console.error('Request failed!');
      }
     
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setSubmitting(false);
  }
}

const handleDelete = async (e, id) => {
    console.log(id)
    const response = await fetch('https://api-routes-rest-mocha.vercel.app/api/deleteComment', { 
        method: 'POST', 
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `id=${id}`
      });
      if (response.ok) {
        console.log('Request succeeded!');
        fetchComments()
      } else {
        console.error('Request failed!');
      }
    

}

const handleUpdate = async (e,id) => {
  e.preventDefault()
  console.log(id)
  try {
    const response = await fetch('https://api-routes-rest-mocha.vercel.app/api/updateComment', { 
      method: 'POST', 
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `id=${id}&name=${name}&content=${content}`
    });
    if (response.ok) {
      console.log('Request succeeded!');
      fetchComments()
    } else {
      console.error('Request failed!');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  } finally {
    setEditingId(null)
    setUpdating(false)
    setName(null)
    setContent(null)
  }
  
}

  useEffect(() => {
    fetchComments()
  },[]);


  const fetchComments = () => {
    fetch(`https://api-routes-rest-mocha.vercel.app/api/comments`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(resultData => {
        setComments(resultData)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  return (

    <Layout  pageTitle="wall">
       <form method="post" onSubmit={handleSubmit}>
       <label>
         name <input name="name" placeholder="anonymous" />
       </label>
       <label>
         <textarea
           name="content"
           placeholder="post anything on our mexican wall!"
           rows={4}
           cols={40}
         />
       </label>
       <hr />
       <button type="submit" disabled={submitting}>post</button>
</form>


{!comments || submitting ? <Audio
  height="80"
  width="80"
  radius="9"
  color="green"
  ariaLabel="loading"
  wrapperStyle
  wrapperClass
/>  : <div>{comments.sort((a, b) => b.id - a.id).filter(el => el.public).map(comment => {
      
      const link = grabLinkFromText(comment.content)
      const insertionPoint = link && findInsertPoint(comment.content)
      const content1 = link && comment.content.slice(0, insertionPoint)
      const content2 = link && comment.content.slice(insertionPoint + link[0].length)
      return <div key={comment.id}>
                      <div className={tweet}>
                            <span className={timestamp}>{new Date(comment.created_at).toLocaleTimeString('en-US') + ' ' + Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
                            <div onClick={() => setEditingId(comment.id)} className={aday}>
                              {updating && editingId == comment.id ? <textarea onChange={e => setName(e.target.value)}>{name}</textarea> : <div className={listItemTitle}>{comment.name || 'anonymous'}</div>}
                              {updating && editingId == comment.id ? <textarea onChange={e => setContent(e.target.value)}>{content}</textarea> : link && <div className={word}>{content1}<a href={link[0]} target="_blank" rel="noopener noreferrer">{link[0]}</a>{content2}</div>|| <div className={word}>{comment.content}</div>}
                            </div>
                            {updating && editingId == comment.id ?
                              <a 
                              href="#"
                              className={editingId == comment.id ? visible : notVisible} 
                              onClick={(e) => {
                                handleUpdate(e,comment.id)
                              }} 
                              aria-label="edit">{<MdDone />}</a> :
                              <a 
                              href="#"
                              className={editingId == comment.id ? visible : notVisible} 
                              onClick={() => {
                                setUpdating(true)
                                setName(comment.name)
                                setContent(comment.content)
                              }} 
                              aria-label="edit">{<MdOutlineEdit />}</a> 
                            }
                            {updating && editingId == comment.id ?  
                            <a 
                              href="#"
                              className={editingId == comment.id ? visible : notVisible}
                              onClick={e => {
                                setUpdating(false)
                              }}
                          
                              aria-label="edit"><IoReturnDownBack /></a> :
                            <a 
                              href="#"
                              className={editingId == comment.id ? visible : notVisible}
                              onClick={e => {
                                handleDelete(e,comment.id)
                              }}
                          
                              aria-label="edit"><IoCloseSharp /></a>
                              }
                  </div>
                </div>
              })}</div>

            }

     
      <button className={upArrow} 
        data-sal="fade"
        data-sal-delay="300"
        data-sal-duration="500"
        data-sal-easing="ease" onClick={() => scrollTo('#blog')}>↑</button>
    </Layout>
  )
}


export default Wall